import { Component, OnInit } from '@angular/core';
import { CartItem } from '../../models/cart-item';
import { ShippingDetails } from '../../models/api/shippingDetails';
import { CartService } from '../../services/cart.service';
import { Checkout } from '../../models/api/checkout';
import { Cart } from '../../models/api/cart';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from '../../services/api.service';
import { CartItem as ApiCartItem } from '../../models/api/cartItem';
import Swal from 'sweetalert2'
import { Pet } from '../../models/pets';
import { PetService } from '../../services/pets.service';
import { Item } from '../../models/item';


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  isLoading = false;
  buttonMsg = "Place Order";
  private pathToAssets: string = "../../assets/";
  pets: Pet[] = new Array();
  items: CartItem[] = new Array()
  chosen: string;
  item: Item = new Item();
  shipping: ShippingDetails = new ShippingDetails()
  paymentOptions = ["Apple Pay", "Cashapp", "Zelle"]
  constructor(private cartService: CartService, private apiService: ApiService, private petService: PetService) {
  }

  getPetName(id: string): string {
    return this.petService.find(id).name
  }

  ngOnInit() {
    this.shipping = new ShippingDetails();
    this.shipping.paymentOption = this.paymentOptions[1]
    this.cartService.getItems().subscribe((data: CartItem[]) => {
      this.items = data;
      this.pets = this.petService.findAll().filter(pet => !pet.adopted)
      if (this.items.length > 0) {
        this.chosen = this.items[0].pet.id
      } else {
        this.chosen = this.pets[0].id
      }
    })
  }

  onChange(value: string) {
    console.log(value)
    let item: Item = new Item();
    item.pet = this.petService.find(value)
    this.cartService.add(item)
  }

  total() {
    return this.cartService.total();
  }

  grandTotal() {
    return this.cartService.grandTotal();
  }

  submit() {
    this.isLoading = true;
    this.buttonMsg = "Loading...";
    if (this.items.length < 1) {
      Swal({
        title: 'Oops!',
        text: 'Your cart is empty try adding some items to your cart before trying to checkout',
        type: 'warning',
        confirmButtonText: 'Ok got it!',
      })
      this.isLoading = false;
      this.buttonMsg = "Place Order";
    } else {
      let checkout = new Checkout();
      let cart = new Cart()
      this.items.forEach((value: CartItem) => {
        let apiCartItem: ApiCartItem = new ApiCartItem();
        apiCartItem.petName = value.pet.name;
        apiCartItem.productTotal = value.pet.price
        cart.cartItems.push(apiCartItem)
      })
      cart.grandTotal = this.cartService.total();
      checkout.cart = cart;
      checkout.shippingDetails = this.shipping;
      this.apiService.checkout(checkout)
        .subscribe((data: any) => {
          this.isLoading = true;
          this.shipping = new ShippingDetails()
          this.cartService.purge()
          Swal({
            title: 'Thanks!',
            text: 'Thank you! You order has been received. We will contact you as soon as possible',
            type: 'success',
            confirmButtonText: 'Ok',
          })
          this.isLoading = false;
          this.buttonMsg = "Place Order";
        },
          (err: HttpErrorResponse) => {
            this.isLoading = false;
            Swal({
              title: 'Error!',
              text: 'Unable to place order. Make sure you are connected to the internet',
              type: 'warning',
              confirmButtonText: 'Ok got it!',
            })
            this.buttonMsg = "Place Order";
          });
    }
  }

  itemTotal(item: CartItem): number {
    let total: number = this.cartService.totalItems();
    return total;
  }

  getPrice(item: CartItem) {
    return this.cartService.getPrice(item.pet);
  }

  Shipping() {
    return this.cartService.shipping();
  }
}
